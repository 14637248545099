<template>
    <a class="_result"
       :class="{'is-active': isCurrent }"
       :href="getUri(data)"
       :title="getName(data)"
       >

        <div class="_result-image">
            <img :src="data.id_default_image" :alt="getName(data)">
        </div>
        <div class="_result-content">
            <p class="_result-title" v-html="getTitle(data, search)"></p>

            <template v-if="hasDiscount(data)">
                <span class="_result-price-discount">{{ getPriceDiscount(data) + currency }}</span>
                <span class="_result-percent">- {{ getPercent(data) }}%</span>
            </template>
        </div>
    </a>
</template>


<script>
import '../../style/Result.scss'
import { resultSelector } from '../../selector'
import { onMounted, ref } from 'vue'

export default {
    name: 'Product',
    props: {
        data: Object,
        search: String,
        index: Number,
        isCurrent: Boolean
    },
    setup({ data, search, isCurrent }) {
        const focus = ref(false)
        const currency = ref(null)

        const { getUri, getName, getTitle, hasDiscount, getPriceDiscount, getPercent, getCurrency } = resultSelector

        onMounted(() => {
            currency.value = getCurrency()
        })

        return {
            data,
            search,
            isCurrent,
            focus,
            currency,
            getUri,
            getName,
            getTitle,
            hasDiscount,
            getPriceDiscount,
            getPercent
        }
    }
}
</script>
