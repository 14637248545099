/**
 *
 * @returns {{width: *, height: *}}
 */
export const viewport = () => {
    let e = window, a = 'inner';
    if ( !('innerWidth' in window ) ) {
        a = 'client';
        e = document.documentElement || document.body;
    }
    return { width : e[ a+'Width' ] , height : e[ a+'Height' ] };
};


/**
 *
 * @param fn
 * @param time
 * @returns {Function}
 */
export const debounce = (fn, time) => {
    let timeout;
    return function() {
        const functionCall = () => fn.apply(this, arguments);
        clearTimeout(timeout);
        timeout = setTimeout(functionCall, time);
    }
};


/**
 *
 * @param str
 */
export const copyToClipboard = str => {
    const el = document.createElement('textarea');
    const selected = document.getSelection().rangeCount > 0
                   ? document.getSelection().getRangeAt(0)
                   : false;

    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';

    document.body.appendChild(el);

    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
    }
};


/**
 *
 * @param message
 * @param state
 */
export const createAlerts = (message, state) => {
    const alert = document.createElement('span');

    alert.innerHTML = message.trim();
    alert.className = `alert alert-${state}`;

    alert.addEventListener('click', () => {
        alert.style.display = 'none';
    });

    document.body.appendChild(alert);
};

/**
 *
 * @param name
 * @param search
 * @returns {RegExpExecArray | string}
 */
export const getParameterByName = (name, search) =>  {
    const match = RegExp('[?&]' + name + '=([^&]*)').exec(search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}