export const myTabs = document.querySelectorAll("ul.nav-tabs > li");

export const myTabClicks = (tabClickEvent) => {
    tabClickEvent.preventDefault();

    myTabs.forEach(el => {
        el.classList.remove("active");
    });

    let clickedTab = tabClickEvent.currentTarget;

    clickedTab.classList.add("active");

    let myContentPanes = document.querySelectorAll(".tab-pane");

    myContentPanes.forEach(el => {
        el.classList.remove("active");
    });

    let anchorReference = clickedTab.querySelector("a");
    let activePaneId = anchorReference.getAttribute("href");
    let activePane = document.querySelector(activePaneId);

    activePane.classList.add("active");
};


