export const apiToken = 'Basic NkVNNk03TEJNN1RDWFZBTVRXQ1FOMUlGNFlNUjlOQzM6';

export const config = {
    headers: {
        Authorization: apiToken
    },
    params:  {
        output_format: 'JSON',
    },
}

/**
 *
 * @param params
 * @returns {{params: {output_format: string}}}
 */
export const getParams = (params) => {
    return {
        headers: {
            Authorization: apiToken,
        },
        params:  {
            output_format: 'JSON',
            ...params
        },
    }
}
