import { getParams } from "./index";
import { uriProduct } from '../common/types.js'
import {getProductImage} from "../selector"
import axios from 'axios'

/**
 *
 * @param name
 * @param limit
 * @returns {PromiseLike<Array>}
 */
export const getResults = (name, limit = 10) => {
    return axios.get(
        uriProduct,
        getParams({
            'filter[name]': `%[${name}]%`,
            display: '[id,id_default_image,name,link_rewrite,price_with_tax,price_with_discount]',
            limit
        })
    )
    .then(({ data: { products } }) => {
        products.forEach((ele) => {
           getProductImage(ele, products);
        });
        return products
    }).catch(response => {
        // error callback
        console.log(response.message);
        return [];
    });
};
