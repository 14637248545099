<template>
    <div class="app-search"
         @keyup.down="keyDown"
         @keyup.up="keyUp"
         @keyup.enter="submit"
    >


        <div class="_mod-search Icon-search"
             @click.stop="submit"
             @click="toggle"
             :class="{ 'has-spinner': isLoading, 'is-active': isActive || !isSmallScreen().value }"
        >
            <input type="search"
                   @click.stop
                   @input="changeSearch(); listResults();"
                   v-model="search"
                   :placeholder="placeholder"
                   :aria-label="label"
                   @focus.capture="[showResults = true, currentIndex = -1]"
                   :class="{'remove-caret': currentIndex > -1}"
                   ref="input"
            />
        </div>

        <div :class="{ 'is-active': hasSearched && showResults }"
             class="_search-results"
             :style="{ maxHeight: maxHeight().value }"
        >
            <template v-if="results.length">
                <div v-for="(result, i) in results" :key="result.id">
                    <app-result
                            :data="result"
                            :search="search"
                            :index="i"
                            :isCurrent="i === currentIndex"
                            :key="i"
                    />
                </div>
            </template>
            <p v-else class="_search-results-none">{{ empty }}</p>
        </div>

    </div>
</template>


<script>
    import '../../style/Search.scss'
    import Result from './Result'
    import * as type from '../../common/types'
    import { default as eventBus } from '../../common/eventBus'
    import {getProductsImage} from '../../selector'
    import { getResults } from '../../api/search'
    import { ref, computed, onMounted } from 'vue'
    import { debounce } from '../../../utils'
    import { viewport } from '../../../utils'

    export default {
        name: 'Search',
        props: {
            placeholder: String,
            svg: String,
            label: String,
            empty: String,
            limit: Number
        },
        components: {
            'app-result': Result
        },
        setup({ placeholder, label, empty, limit }) {
            const search = ref('')
            const results = ref([])
            const currentIndex = ref(-1)
            const isActive = ref(false)
            const hasSearched = ref(false)
            const showResults = ref(false)
            const isLoading = ref(false)
            const input = ref(false)

            const listResults = debounce(async () => {
                if (search.value.length < 3) return
                isLoading.value = true
                results.value = await getResults(search.value, limit)
                formatResult(results.value)
                isLoading.value = false
            }, 500)

            const isSmallScreen = () => computed(() => {
                return viewport().width <= 767
            })

            const formatResult = (results) => {
                hasSearched.value = true;
                showResults.value = true;
                getProductsImage(results)
            }

            const submit = () => {
                if (isSmallScreen().value) {
                    return
                }
                if (search.value === '') {
                    return;
                }
                if (currentIndex.value < 0) {
                    window.location.href = type.uriSearch + search.value;
                    return;
                }

                const result = results.value[currentIndex.value];
                const resultLink = result.querySelector('a');
                resultLink.click();
            }

            const toggle = () => {
                if (!isSmallScreen().value) {
                    return
                }
                isActive.value = !isActive.value
            }

            const keyDown = () => {
                if (currentIndex.value < results.value.length -1) currentIndex.value += 1;
            }

            const keyUp = () => {
                if (currentIndex.value > -1) currentIndex.value -= 1;
            }

            const changeSearch = () => {
                showResults.value = !(search.value.length < 3);
            }

            const maxHeight = () => computed(() => {
                return type.MAX_HEIGHT_RESULTS + 'px'
            })

            onMounted(() => {
                eventBus.on(type.SEARCH_IS_HIDDEN, () => {
                    isActive.value = false;
                });
                eventBus.on(type.RESULTS_ARE_HIDDEN, () => {
                    showResults.value = false;
                });
            })

            return {
                placeholder,
                label,
                empty,
                search,
                results,
                currentIndex,
                isActive,
                hasSearched,
                showResults,
                isLoading,
                input,
                listResults,
                isSmallScreen,
                submit,
                toggle,
                keyDown,
                keyUp,
                changeSearch,
                maxHeight
            }
        }
    }
</script>
