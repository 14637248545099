<template>
    <div class="_box" :class="[boxClassType().value, { 'is-align': isAlign().value, 'is-active': isActiveBox && isActiveParent().value }]">

        <!-- Title -->
        <span>{{ box.name }}</span>

        <!-- Links -->
        <ul v-if="isCategory" v-show="!isMobile() || isActiveBox" :style="oneColumnFullWidth" >
            <li v-for="(element, i) in box.elements" :key="i" class="_box-link">
                <a :href="element.url">{{ element.name }}</a>
            </li>
        </ul>

    </div>
</template>


<script>
import '../../style/Boxes.scss'
import * as type from '../../common/types'
import { boxSelector } from '../../selector'
import { ref, onMounted, computed, watch } from 'vue'

export default {
    name: 'Boxes',
    props: {
        box: Object,
        oneColumnFullWidth: Object,
        isActiveMenu: Boolean
    },
    setup({ box, oneColumnFullWidth, isActiveMenu }) {
        const isActiveBox = ref(false)

        const { isMobile, getBoxClassType } = boxSelector

        const handleClick = (e) => {
            if (!isMobile()) return;
            e.preventDefault();
            isActiveBox.value = !isActiveBox.value;
        }

        const boxClassType = () => computed(() => getBoxClassType(box))

        const isActiveParent = () => computed(() => isActiveMenu)

        const isCategory = () => computed(() => boxClassType().value === type.BOX_CLASS_CATEGORY)

        // const isSpecial = () => computed(() => boxClassType().value === type.BOX_CLASS_SPECIAL)

        const isAlign = () => computed(() => box.active_mobile === type.BOX_BOOL_ALIGN)

        watch(isActiveParent, (newValue, oldValue) => {
            if (!newValue) isActiveBox.value = false
        })

        // onMounted(() => {
        //     this.$parent.isSpecialType  = isSpecial;
        //     this.$parent.isCategoryType = isCategory;
        // })

        return {
            box,
            oneColumnFullWidth,
            isActiveMenu,
            isActiveBox,
            isMobile,
            handleClick,
            boxClassType,
            isActiveParent,
            isCategory,
            isAlign
        }
    }
}
</script>
