const slides = document.querySelectorAll(".home-top-reassurance #block-reassurance ul > li");
const homepage = document.querySelector('.page-index');

let current = 0;

export const tickerReassurance = () => {
    if (slides && homepage) {
        if (window.screen.width < 992) {
            setInterval(function () {
                slides.forEach((e) => {
                    e.style.transform = 'translateY(-100%)';
                    e.style.opacity = 0;
                });
                current = (current !== slides.length - 1) ? current + 1 : 0;
                slides[current].style.transform = 'translateY(0)';
                slides[current].style.opacity = 1;
            }, 3000);
        }
    }
};
