<template>
    <a class="_tab"
       @mouseover="isNotMobileFn(showMenu);"
       @click.stop="checkMenu"
       :href="tab.url"
       ref="tabs"
       :class="{'is-active': isActive}"
       :style="tabColor().value"
        >
        <span class="_tab-title">{{ tab.title }}</span>
    </a>
</template>


<script>
import '../../style/Tab.scss'
import { tabSelector } from '../../selector'
import { MENU_IS_DISPLAYED } from '../../common/types'
import { default as eventBus } from '../../common/eventBus.js'
import { ref, toRefs, computed } from 'vue'

const protocol = window.location.protocol;
const host = window.location.host;
const imgPath = 'img/custom';
const siteUrl = protocol + '//' + host + '/' + imgPath + '/';

export default {
    name: 'Tab',
    props: {
        tab: Object,
        index: Number,
        isActive: Boolean,
        firstOver: Array
    },
    setup(props) {
        const tabs = ref(null)
        const { isActive, firstOver } = toRefs(props)

        const { isNotMobileFn, menuMobile } = tabSelector

        const showMenu = () => {
            firstOver.value.push(props.index)
            eventBus.dispatch(MENU_IS_DISPLAYED, props.index)
        }

        const checkMenu = (e) => {
            if (!hasMenu()) return;
            e.preventDefault();
            const newIndex = isActive.value ? null : props.index;
            eventBus.dispatch(MENU_IS_DISPLAYED, newIndex, false);
        }

        const hasMenu = () => {
            return props.tab.columnsWrap.length
        }

        const tabColor = () => computed(() => {
            return !menuMobile()
                ? {
                    backgroundColor: props.tab.fnd_color_menu_tab
                }
                : {
                    backgroundColor: '#E5F7FD'
                };
        })

        return {
            tab: props.tab,
            isActive,
            tabs,
            isNotMobileFn,
            showMenu,
            checkMenu,
            tabColor
        }
    }
}
</script>
