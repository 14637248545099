import { createApp } from 'vue'
import Menu from './vue/components/Menu/Menu'
import { default as eventBus } from './vue/common/eventBus'
import { MENU_IS_TRIGGER, MENU_IS_DISPLAYED, MENU_IS_HIDDEN } from './vue/common/types'
import { appMenu, menuTrigger,pageOrder } from './vue/selector/elements'
import { isMobile } from './vue/selector'

// Vue.http.options = {
//     headers: {
//         Authorization: apiToken
//     },
//     params:  {
//         output_format: 'JSON',
//     },
// };

if (appMenu && !pageOrder) {
    createApp(Menu)
        .mount(appMenu)

    // Mobile event [Click]
    menuTrigger.addEventListener('click', (e) => {
        e.stopPropagation();
        eventBus.dispatch(MENU_IS_TRIGGER, true);
    });

    // Mobile event [Click]
    document.body.addEventListener('click', () => {
        if (!isMobile()) return;
        eventBus.dispatch(MENU_IS_HIDDEN);
    });

    // Desktop event [MouseMove]
    document.body.addEventListener('mousemove', () => {
        if (isMobile()) return;
        eventBus.dispatch(MENU_IS_DISPLAYED, null);
    });
}
