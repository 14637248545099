<template>
    <div id="app-menu" @mousemove.stop @click.stop :class="{'menu-is-open': menuIsOpen}">

        <!-- desktop layout -->
        <template v-if="!menuMobile()">
            <div class="_header-nav">
                <app-tab v-for="(tab, index) in json"
                         :key="index"
                         :index="index"
                         :tab="processTab(tab)"
                         :is-active="isCurrentMenu(index)"
                         :first-over="firstOver"
                />
            </div>
            <div class="_menus">
                <div class="_menu"
                     :class="{ 'is-active': isCurrentMenu(index) }"
                     v-for="(menu, index) in filteredJson().value">

                    <app-column v-for="(columns, index) in menu.columnsWrap"
                                :key="index"
                                :index="index"
                                :name="getColumnName(columns)"
                                :valueOver="getColumnValueOver(columns)"
                                :color="getColumnColor(columns)"
                                :background="getColumnBackground(columns)"
                                :length="getColumnsLength(menu)"
                                :columns="getColumns(columns)"
                    />
                </div>
            </div>
        </template>
        <!-- / desktop layout -->


        <!-- mobile layout -->
        <template v-else v-for="(item, index) in json">
            <app-tab :index="index"
                     :tab="processTab(item)"
                     :is-active="isCurrentMenu(index)"
                     :first-over="firstOver"
            />

            <div class="_menu"
                 :class="{ 'is-active': isCurrentMenu(index) }">

                <template v-if="getColumnsWrap(item).length">
                    <app-column v-for="(columns, i) in item.columnsWrap"
                                :isActiveMenu="isCurrentMenu(index)"
                                :key="i"
                                :index="i"
                                :columns="getColumns(columns)"
                    />
                </template>
            </div>
        </template>
        <!-- / mobile layout -->

    </div>
</template>


<script>
import '../../style/Menu.scss'
import Tab from './Tab'
import Columns from './Columns'
import { appSelector } from '../../selector'
import * as type from '../../common/types'
import { getJson } from '../../api/getter'
import { default as eventBus } from '../../common/eventBus.js'
import { menuTrigger } from '../../selector/elements'
import { onMounted, ref, computed } from 'vue'

export default {
    name: 'Menu',
    components: {
        'app-tab': Tab,
        'app-column': Columns
    },
    setup() {
        const json = ref([])

        const {   menuMobile,
                  getColumnsWrap,
                  getColumnName,
                  getColumnValueOver,
                  getColumnColor,
                  getColumnBackground,
                  getColumnsLength,
                  getColumns
        } = appSelector

        const currentMenuIndex = ref(null)
        const menuIsOpen       = ref(false)
        const dropdownIsOpen   = ref(false)
        const position         = ref(type.BANNER_POSITION_MENU)
        const firstOver        = ref([])

        const isCurrentMenu = (index) => {
            return currentMenuIndex.value === index
        }

        const processTab = ({ name, url, fnd_color_menu_tab, columnsWrap = [] }) => {
            const [ title, subTitle ] = name.split(/\n/);
            return {
                url,
                subTitle,
                title,
                fnd_color_menu_tab,
                columnsWrap
            }
        }

        const filteredJson = () => computed(() => {
            return json.value.filter(menu => getColumnsWrap(menu).length)
        })

        onMounted(() => {
            json.value = getJson()

            eventBus.on(type.MENU_IS_DISPLAYED, ({ detail }) => {
                if (dropdownIsOpen.value) return;
                currentMenuIndex.value = detail;
            });
            eventBus.on(type.MENU_IS_TRIGGER, () => {
                if (!menuIsOpen.value) {
                    eventBus.dispatch(type.SEARCH_IS_HIDDEN);
                } else {
                    currentMenuIndex.value = null;
                }
                // Convert boolean into number
                menuIsOpen.value ^= true;
                menuTrigger.dataset.open = menuIsOpen.value; // 0 or 1
            });
            eventBus.on(type.MENU_IS_HIDDEN, () => {
                if (!menuIsOpen.value) return;
                eventBus.dispatch(type.MENU_IS_TRIGGER);
            });
        })

        return {
            json,
            currentMenuIndex,
            menuIsOpen,
            dropdownIsOpen,
            position,
            firstOver,
            isCurrentMenu,
            processTab,
            menuMobile,
            getColumnsWrap,
            getColumnName,
            getColumnValueOver,
            getColumnColor,
            getColumnBackground,
            getColumnsLength,
            getColumns,
            filteredJson
        }
    },
}
</script>

