<template>
    <div class="_column"
         v-show="!isMobile()"
         :style="{ width, background, color }">

        <app-box v-for="(box, index) in columns"
                 :key="index"
                 :box="box"
                 :oneColumnFullWidth="oneColumnFullWidth().value"
                 :isActiveMenu="isActiveMenu"
        />
    </div>
</template>


<script>
import '../../style/Columns.scss'
import Boxes from './Boxes'
import { isMobile } from '../../selector'
import { computed } from 'vue'

export default {
    name: 'Columns',
    props: {
        columns: Array,
        index: Number,
        length: Number,
        name: String,
        valueOver: String,
        background: String,
        color: String,
        isActiveMenu: Boolean
    },
    components: {
        'app-box': Boxes
    },
    setup({ columns, index, length, color, isActiveMenu }) {

        const width = () => {
            return 100 / length + '%'
        }

        const oneColumnFullWidth = () => computed(() => {
            return length < 2
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    height: '300px'
                }
                : {};
        })

        return {
            columns,
            index,
            color,
            isMobile,
            width,
            oneColumnFullWidth,
            isActiveMenu
        }
    }
}
</script>
