// Event Bus
export const MENU_IS_DISPLAYED = 'eventBus/MENU_IS_DISPLAYED';
export const MENU_IS_TRIGGER   = 'eventBus/MENU_IS_TRIGGER';
export const MENU_IS_HIDDEN    = 'eventBus/MENU_IS_HIDDEN';

export const SEARCH_IS_HIDDEN   = 'eventBus/SEARCH_IS_HIDDEN';
export const RESULTS_ARE_HIDDEN = 'eventBus/RESULTS_ARE_HIDDEN';


// Json constants
export const BOX_TYPE_SPECIAL   = '2';
export const BOX_TYPE_CATEGORY  = '3';
export const BOX_TYPE_IMAGE     = '7';
export const BOX_BOOL_ALIGN     = '0';


// CSS classes
export const BOX_CLASS_CATEGORY = 'is-category';
export const BOX_CLASS_SPECIAL  = 'is-special';
export const BOX_CLASS_IMAGE    = 'is-image';


// CSS
export const MAX_WIDTH_MOBILE   = 1320;
export const BREAKPOINT_MENU    = 1320;
export const MAX_HEIGHT_RESULTS = 392; // 78 * 5 +2


// Banner position
export const BANNER_POSITION_MENU    = 'menu';


// API
export const uriProduct = '/api/products';
export const uriImage = '/api/images/products/';
export const uriSearch = '/recherche?controller=search&s=';


export const DEFAULT_CURRENCY = '€';
export const DEFAULT_IMAGE = '/themes/assets/img/icon/no-image.png';