const buttonValidate = document.querySelector('.slide-container .validate-filter');
const tagSelect = document.querySelector('.slide-container #select-sac');


export const filterBag = () => {
    if (buttonValidate && tagSelect) {
        buttonValidate.addEventListener('click', () => {
            const indexTagSelect = tagSelect.selectedIndex;
            const optionSelected = tagSelect.options;
            window.location = optionSelected[indexTagSelect].value;
        });
    }
};