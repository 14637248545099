class EventBus {
    constructor () {
        this.el = document.createElement('div')
    }

    on(event, callback) {
        this.el.addEventListener(event, callback)
    }

    dispatch(event, detail = {})
    {
        this.el.dispatchEvent(new CustomEvent(event, { detail }));
    }
}

export default new EventBus()
