export const thumbList = document.querySelectorAll('.js-qv-mask .thumb-container');
export const productPage = document.querySelector('body#product');
export const div1ToDelete = document.querySelector('.product-bloc-right .js-qv-mask');
export const div2ToDelete = document.querySelector('.product-bloc-left .js-qv-mask');
export const div3ToDelete = document.getElementById('thumbnails');

export const removeDivOfDOM = (node) => {
    if (thumbList.length <= 3 && productPage && node) {
        return node.parentNode.removeChild(node);
    }
};