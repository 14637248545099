const buttonValidate = document.querySelector('.qui-etes-vous .validate-filter');
const tagSelect = document.querySelector('.qui-etes-vous #select-secteur');


export const filterTrade = () => {
    if (buttonValidate && tagSelect) {
        buttonValidate.addEventListener('click', () => {
            const indexTagSelect = tagSelect.selectedIndex;
            const optionSelected = tagSelect.options;
            window.location = optionSelected[indexTagSelect].value;
        });
    }
};
