
// App elements
export const appSearch = document.querySelector('.app-search');
export const appMenu = document.getElementById('vm-app-menu');


// Action elements
export const menuTrigger = document.getElementById('js-toggle-menu');

// Pages
export const pageOrder = document.querySelector('body.page-order');
