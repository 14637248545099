import * as types from '../common/types';
import { viewport } from '../../utils'
import {uriImage} from "../common/types";
import axios from 'axios'
import { config } from '../api'

/**
 *
 * @param columnsWrap
 * @returns {*}
 */

const getColumnsWrap = ({columnsWrap}) => {
    return defaultValue(columnsWrap, []);
};


/**
 *
 * @param columns
 * @returns {*}
 */
const getColumns = ({columns}) => {
    return defaultValue(columns, []);
};


/**
 *
 * @param bg_color
 * @returns {*}
 */
const getColumnBackground = ({bg_color}) => {
    return defaultValue(bg_color, '');
};


/**
 *
 * @param txt_color_element
 * @returns {*}
 */
const getColumnColor = ({txt_color_element}) => {
    return defaultValue(txt_color_element, '');
};


/**
 *
 * @param internal_name
 * @param value
 * @returns {*}
 */
const getColumnName = ({internal_name}, value = '') => {
    return defaultValue(internal_name, value);
};

/**
 *
 * @param internal_name
 * @param value
 * @returns {*}
 */
const getColumnValueOver = ({value_over}, value = '') => {
    return defaultValue(value_over.replace(/<\/?[^>]+(>|$)/g, ""), value);
};


/**
 *
 * @param target
 * @param defaultValue
 * @returns {*}
 */
const defaultValue = (target, defaultValue) => {
    return target ? target : defaultValue
};


/**
 *
 * @param columns
 * @returns {*}
 */
const getColumnsLength = ({columnsWrap}) => {
    return Object.keys(columnsWrap).length
};


/**
 *
 * @param type
 * @returns {string}
 */
const getBoxClassType = ({type}) => {
    switch(type) {
        case types.BOX_TYPE_CATEGORY: return types.BOX_CLASS_CATEGORY;
        case types.BOX_TYPE_SPECIAL: return types.BOX_CLASS_SPECIAL;
        case types.BOX_TYPE_IMAGE: return types.BOX_CLASS_IMAGE;
        default: return '';
    }
};


/**
 *
 * @param type
 * @param boxType
 * @returns {boolean}
 */
export const getBoxType = ({type}, boxType) => {
  return type === boxType;
};


/**
 *
 * @returns {boolean}
 */
export const isMobile = () => {
    return viewport().width <= types.MAX_WIDTH_MOBILE;
};

/**
 *
 * @returns {boolean}
 */
export const menuMobile = () => {
    return viewport().width <= types.BREAKPOINT_MENU;
};

/**
 *
 * @returns {boolean}
 */
export const isNotMobileFn = (callback) => {
    if(!isMobile()) {
        callback();
    }
};


/**
 *
 * @param callback
 */
export const isMobileFn = (callback) => {
  if (isMobile()) {
      callback();
  }
};


/**
 *
 * @param name
 * @returns {*}
 */
const getProductName = ({ name }) => name;


/**
 *
 * @param data
 * @param search
 * @returns {string}
 */
const getProductTitle = (data, search) => {
    const baseName = getProductName(data).toLowerCase();
    const searchValue = search.toLowerCase();
    const replacement = '<strong>' + searchValue + '</strong>';

    return baseName.replace(searchValue, replacement);
};


/**
 *
 * @param id
 * @returns {string}
 */
const getProductUri = ({ id }) => {
    return  '?controller=product&id_product='+id
};

/**
 *
 * @param price
 * @returns {string}
 */
const getPrice = (price) => parseFloat(price).toFixed(2);

/**
 *
 * @param price
 * @returns {string}
 */
const getProductPrice = ({ price_with_tax }) => {
    return getPrice(price_with_tax);
};

/**
 *
 * @param price
 * @returns {string}
 */
const getProductPriceDiscount = ({ price_with_discount }) => {
    return getPrice(price_with_discount);
};

/**
 *
 * @param data
 * @returns {boolean}
 */
const hasDiscount = (data) => {
    return getProductPriceDiscount(data) !== getProductPrice(data);
};

/**
 *
 * @param data
 * @returns {number}
 */
const getPercent = (data) => {
    return 100 - Math.round(getProductPriceDiscount(data) * 100 / getProductPrice(data));
};

/**
 *
 * @returns {*}
 */
const getCurrency = () => {
    const {currencySign} = document.documentElement.dataset;

    return (!currencySign) ? types.DEFAULT_CURRENCY : currencySign;
};

/**
 *
 * @param products
 * @returns {*}
 */
export const getProductsImage = (products) => {
    products.forEach((ele) => {
        getProductImage(ele, products)
    })
};

/**
 *
 * @param id
 * @param id_default_image
 * @param products
 * @returns {PromiseLike<T | never>}
 */
export const getProductImage = ({id, id_default_image}, products) => {
    const product = products.find((ele)=> ele.id === id);
    return axios.get(
            uriImage + id +'/' + id_default_image + '/small_default',
            {
                ...config,
                responseType: 'blob'
            }
        )
        .then(({data})  => {
            if (product) product.id_default_image = URL.createObjectURL(data)
        }).catch(error => {
            if (product) product.id_default_image = types.DEFAULT_IMAGE
        })
};


export const appSelector = {
    'getColumnsWrap': (columnsWrap) => getColumnsWrap(columnsWrap),
    'getColumns' : (columns) => getColumns(columns),
    'getColumnsLength': (columnsWrap) => getColumnsLength(columnsWrap),
    'getColumnBackground': (bg_color) => getColumnBackground(bg_color),
    'getColumnColor': (text_color) => getColumnColor(text_color),
    'getColumnName': (name) => getColumnName(name),
    'getColumnValueOver': (valueOver) => getColumnValueOver(valueOver),
    'isMobile': () => isMobile(),
    'menuMobile': () => menuMobile()
};


export const boxSelector = {
    'getBoxClassType': (type) => getBoxClassType(type),
    'isMobile': () => isMobile()
};


export const tabSelector = {
    'isMobile': () => isMobile(),
    'menuMobile': () => menuMobile(),
    'isNotMobileFn': (callback) => isNotMobileFn(callback)
};


export const searchSelector = {
    'isMobile': () => isMobile(),
    'isMobileFn': (callback) => isMobileFn(callback),
};


export const resultSelector = {
    'getName': (name) => getProductName(name),
    'getUri': (id) => getProductUri(id),
    'getPrice': (price) => getProductPrice(price),
    'getPriceDiscount': (price) => getProductPriceDiscount(price),
    'hasDiscount': (data) => hasDiscount(data),
    'getTitle': (data, search) => getProductTitle(data, search),
    'getPercent' : (data) => getPercent(data),
    'getCurrency' : () => getCurrency()
};
