export const getJson = () => {
    const appJsonEl = document.getElementById('app-data-json');

    if (!appJsonEl) {
        return []
    }

    const { json } = appJsonEl.dataset;

    return (json) ? JSON.parse(json): [];
};

