import {myTabClicks, myTabs} from './product-page/navTabs';
import {removeDivOfDOM, div1ToDelete, div2ToDelete, div3ToDelete} from './product-page/thumbnails';
import {scrollToDevis, linkOfDevis, buttonAskDevis} from './product-page/scrollToDevis';
import {tickerReassurance} from './ticker-reassurance/ticker-reassurance';
import {switchPhone} from './header/switchPhone';
import {filterTrade} from './filters/filter-trade';
import {filterBag} from './filters/filter-bag';
import { paginateBlog, copyBlogURL } from './blog/blog';

document.addEventListener('DOMContentLoaded', () => {
    // Nav tabs
    myTabs.forEach(el => {
        el.addEventListener('click',myTabClicks);
    });

    // Scroll to Devis form
    if (buttonAskDevis && linkOfDevis) {
        buttonAskDevis.addEventListener('click', scrollToDevis);
    }

    // Delete Div of DOM if thumbnail miniatures if = 1
    removeDivOfDOM(div1ToDelete);
    removeDivOfDOM(div2ToDelete);
    removeDivOfDOM(div3ToDelete);

    // Delete margin of an element in product quantity
    const deleteMarginQty = document.getElementById('quantity_wanted');
    if (deleteMarginQty) {
        deleteMarginQty.classList.remove('input-group');
    }

    // Ticker Reassurance
    tickerReassurance();

    // Switch picto & num phone
    switchPhone();

    // Filter trade & bag
    filterTrade();
    filterBag();

    // Blog
    paginateBlog();
    copyBlogURL();
});