import Search from './vue/components/Search/Search'
import { SEARCH_IS_HIDDEN, RESULTS_ARE_HIDDEN } from './vue/common/types'
import { appSearch } from './vue/selector/elements'
import { default as eventBus } from './vue/common/eventBus.js'
import { createApp } from 'vue'

if (appSearch) {
    const { placeholder, label, empty, classes } = appSearch.dataset;

    createApp(Search, {
        limit: 10,
        placeholder: placeholder,
        label: label,
        empty: empty,
        svg: '',
        classes: classes
    }).mount(appSearch)

    document.body.addEventListener('click', () => {
        eventBus.dispatch(SEARCH_IS_HIDDEN);
        eventBus.dispatch(RESULTS_ARE_HIDDEN);
    });
}
