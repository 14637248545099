import { copyToClipboard } from '../utils'

/**
 *
 */
export const paginateBlog = () => {
    const $btnSowMoreArticles = document.querySelector('.js-blog-show-more-articles');
    const $linstingContainer = document.querySelector('.blog-listing');
    let data = {
        limit: 5,
        offset: 10
    };
    if ($btnSowMoreArticles && $linstingContainer) {
        $btnSowMoreArticles.addEventListener('click', () => {
            let url = 'blog/ajaxarticle?limit=' + data.limit + '&offset=' + data.offset;
            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
            }).then(response => {
                return response.json()
            }).then(({articles}) => {
                $linstingContainer.insertAdjacentHTML('beforeend', articles);
            }).then((x) => {
                data.offset += data.limit;
            }).catch(reason => {
            });
        })
    }
};


/**
 *
 */
export const copyBlogURL = () => {
    const action = document.querySelector('.js-copy-url');

    if (!action) {
        return;
    }

    action.addEventListener('click', () => {
        const currentURL = window.location;
        copyToClipboard(currentURL);
    });
};